import React from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import { Constrain, GeneralBody } from '../components/Containers';
import { Article, Description, PageTitle } from '../components/Typography';
import GeneralHero from '../components/GeneralHero';
import { Grid, Main, Sidebar, SidebarHeading } from '../components/SiteGrid';
import Cta from '../components/Cta';
import { ReactComponent as ContactEmail } from '../images/contact-email.svg';
import { ReactComponent as SupportEmail } from '../images/support-email.svg';
import { buttonResetCss, ns, gv } from '../utils';

const EmailButton = styled.button`
	${buttonResetCss};
	display: block;
	margin: 1em 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: ${props => ns(props.theme.fz.large2)};
	color: ${gv('textColor')};
	svg {
		height: 1em;
		width: auto;
		fill: currentColor;
		display: block;
	}
`;

export default function Features(props: PageProps) {
	return (
		<>
			<Seo
				title="WPEForm Support and Inquiry - Get in touch"
				description="If you need assistance with WPEForm WordPress Form Builder, this is where from you can get in touch with us."
			/>
			<GeneralHero>
				<PageTitle className="centered" as="h1">
					Inquiry and Support
				</PageTitle>
				<Description className="centered">
					Do you have a support question or a general inquiry? Please choose the
					correct section below and get in touch.
				</Description>
			</GeneralHero>
			<Constrain className="narrow">
				<GeneralBody>
					<Grid>
						<Main>
							<SidebarHeading>Support For Paying Customers</SidebarHeading>
							<Article>
								<p>
									We provide support to our paying customer over email. Please
									click on the button below to initiate a support request.
								</p>
							</Article>
							<Cta.Group align="center" size="regular">
								<Cta
									type="primary"
									size="regular"
									to={() => {
										if (typeof window !== 'undefined') {
											try {
												window.open(
													// eslint-disable-next-line no-useless-concat
													'mai' +
														'lto:su' +
														'ppor' +
														't@wpe' +
														"form.io?subject=Support%3A&body=Please%20explain%20in%20brief%20the%20issue%20you're%20facing.%20We%20check%20against%20email%20for%20actively%20supported%20customers.%20Kindly%20use%20the%20email%20from%20where%20you've%20purchased.%20You%20can%20also%20send%20pre-sales%20queries.%0D%0A%0D%0ATHINGS%20TO%20INCLUDE%0D%0A%0D%0AURL%20where%20problem%20is%20visible%3A%0D%0AWPEForm%20Version%3A%0D%0AWordPress%20Version%3A",
													'_blank'
												);
											} catch (e) {
												alert(
													'Something went wrong. Please send us email at support@wpeform.io'
												);
											}
										}
									}}
								>
									Submit a New Ticket
								</Cta>
							</Cta.Group>
							<Article>
								<p>
									Alternately you can send an email directly to the mail id
									below.
								</p>
							</Article>
							<EmailButton
								onClick={e => {
									e.preventDefault();
									if (typeof window !== 'undefined') {
										window.open(`mailto:support@wpeform.io`, '_blank');
									}
								}}
							>
								<SupportEmail />
							</EmailButton>
							<SidebarHeading>Support for Free Customers</SidebarHeading>
							<Article>
								<p>
									We provide support to our free users through WordPress
									community Support Forum. Please visit the forum from the link
									below.
								</p>
							</Article>
							<Cta.Group align="center" size="regular">
								<Cta
									type="default"
									size="regular"
									to="https://wordpress.org/support/plugin/wpeform-lite/"
									target="_blank"
									rel="noreferrer noopener"
								>
									Community Forum
								</Cta>
							</Cta.Group>
						</Main>
						<Sidebar>
							<SidebarHeading>General &amp; Sales Inquiry</SidebarHeading>
							<Article>
								<p>Please send an email directly to the address below.</p>
							</Article>
							<EmailButton
								onClick={e => {
									e.preventDefault();
									if (typeof window !== 'undefined') {
										window.open(`mailto:contact@wpeform.io`, '_blank');
									}
								}}
							>
								<ContactEmail />
							</EmailButton>
						</Sidebar>
					</Grid>
				</GeneralBody>
			</Constrain>
			<FooterCredit />
		</>
	);
}
